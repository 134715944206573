import * as React from "react";
import { Link } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import StyleIcon from '@material-ui/icons/Style';
import Grid from '@material-ui/core/Grid';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Pagination from '@material-ui/lab/Pagination';
import SearchBar from './search';
import TextHighlighter from './search/highlight';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
  pagingWrapper: {
    textAlign: "center",
  },
  pagingItem: {
    display: "inline-block"
  }
}));

const PostList = ({ posts }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [query, setQuery] = React.useState("");
  const [searchedPosts, setSearchedPosts] = React.useState(posts)

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleInputChange = event => {
    console.log(event.target.value);
    const _query = event.target.value;
    if (_query !== "") {
      const _searchedPosts = posts.filter(value => {
        if (value.frontmatter.title.toLowerCase().indexOf(_query.toLowerCase()) !== -1 || value.frontmatter.category.toLowerCase().indexOf(_query.toLowerCase()) !== -1 || value.frontmatter.date.toLowerCase().indexOf(_query.toLowerCase()) !== -1) {
          return value
        } 
      });
      setSearchedPosts(_searchedPosts);
    } else {
      setSearchedPosts(posts);
    }
    setQuery(_query);
  }

  const postsPerPage = 10;
  const indexOfLastPage = currentPage * postsPerPage;
  const indexOfFirstPage = indexOfLastPage - postsPerPage;
  const currentPosts = searchedPosts.slice(indexOfFirstPage, indexOfLastPage);
  const totalPages = Math.ceil(searchedPosts.length / postsPerPage);

  if (searchedPosts.length === 0) {
    return (
      <div>
        <SearchBar handleInputChange={handleInputChange} query={query} searchedPosts={searchedPosts ? searchedPosts : posts}/>
        <p>
          ブログポストは存在しません。
        </p>
      </div>
    )
  }

  return (
    <div>
      <SearchBar handleInputChange={handleInputChange} query={query} searchedPosts={searchedPosts ? searchedPosts : posts}/>
      <ol style={{ listStyle: `none` }}>
        {currentPosts.map(post => {
          const title = post.frontmatter.title || post.fields.slug;
          const category = post.frontmatter.category; 

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline"><TextHighlighter str={title} includes={query}/></span>
                    </Link>
                  </h2>
                  <Grid container spacing={1}>
                    <Grid item xs={0.2}>
                      <CalendarTodayIcon fontSize="small" /> 
                    </Grid>
                    <Grid item xs={0.2}>
                      <small><TextHighlighter str={post.frontmatter.date} includes={query}/></small>
                    </Grid>
                    <Grid item xs={0.2}>
                      <StyleIcon fontSize="small" />
                    </Grid>
                    <Grid item xs={0.2}>
                      <Link to={`/category/${_.kebabCase(category)}`}>
                        <small><TextHighlighter str={category} includes={query}/></small>
                      </Link>
                    </Grid>
                  </Grid>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description ? post.frontmatter.description.slice(0, 100) + " ..." : post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
                <br/>
              </article>
              <hr/>
            </li>
          )
        })}
      </ol>
      <div className={classes.pagingWrapper} >
        <Pagination className={classes.pagingItem} count={totalPages} page={currentPage} onChange={handleChange} />
      </div>
    </div>
  )
}

export default PostList;